.home{
  min-width: 1850px;
  box-sizing: border-box;
}

.arrow {
  position: absolute;
  left: 50%;
  bottom: 20px;
  width: 68px;
  height: 68px;
  border-radius: 34px;
  cursor: pointer;
  transform: translate(-50%,0);
}

.header {
  position: relative;
  display: flex;
  width: 100%;
  height: 820px;
  background: url("img/banner.png") no-repeat center;
  background-size: cover;
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 105px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    img {
      width: 476px;
      height: 75px;
    }
    ul {
      display: flex;
      list-style: none;
      li {
        margin: 0 20px;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid;
        }
      }
      .activated {
        border-bottom: 1px solid;
      }
    }
  }
}

.case {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 100px;
  //height: 100vh;
  h2 {
    font-size: 36px;
    font-weight: 400;
    color: #036DE0;
  }
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: #4D4D4D;
  }
  .cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px;
    width: 75%;
    .card{
      margin-bottom: 36px;
      padding: 40px;
      width: 667px;
      background: #FFFFFF;
      box-shadow: 0 5px 59px 0 rgba(0, 0, 0, 0.04);
      border-radius: 10px;
      .cardHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;
        h3 {
          margin: 0;
          font-size: 32px;
          font-weight: 400;
          color: #4F4F6F;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
      p {
        font-size: 18px;
        font-weight: bold;
        color: #999999;
      }
    }
  }
  .caseDivider {
    margin-bottom: 26px;
    width: 78px;
    height: 2px;
    background: #469DFF;
  }
}

.storage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 902px;
  background: url("img/storage_bg.png") no-repeat;
  background-size: cover;
  .storageContainer {
    display: flex;
    justify-content: space-around;
    width: 75%;
    height: 491px;
    margin-bottom: 200px;
    .text {
      span {
        font-size: 24px;
        font-weight: bold;
        color: #999999;
      }
      h2 {
        position: relative;
        font-size: 60px;
        font-weight: bold;
        color: #4F4F6F;
      }
      p {
        width: 530px;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
      }
    }
    .pic {
      width: 703px;
      height: 491px;
    }
  }
  .storageDivider {
    position: absolute;
    left: -25px;
    bottom: 0;
    width: 10px;
    height: 41px;
    background: #469DFF;
    border-radius: 5px;
    transform: translate(0, -50%);
  }
  .arrow1 {
    position: absolute;
    left: 50%;
    bottom: 20px;
    width: 68px;
    height: 68px;
    border-radius: 34px;
    cursor: pointer;
    transform: translate(-50%,0);
    margin-bottom: 105px;
  }
}

.IDC {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 755px;
  background: url("img/IDC_bg.png") no-repeat;
  background-size: cover;
  h2 {
    margin-top: 58px;
    margin-bottom: 125px;
    font-size: 36px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .IDCPartsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 75%;
    .IDCPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      img {
        margin-bottom: 30px;
        width: 120px;
        height: 120px;
      }
    }
  }
  .scenario {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 520px;
    width: 1405px;
    height: 564px;
    background: #FFFFFF;
    box-shadow: 0 12px 41px 0 rgba(210, 210, 210, 0.5);
    border-radius: 12px;
    h2 {
      margin: 56px 0 48px 0;
      font-size: 36px;
      font-weight: bold;
      color: #4F4F6F;
    }
    .scenarioPartsContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      .scenarioPart {
        width: 360px;
        .scenarioPartHeader {
          display: flex;
          align-items: center;
          margin-bottom: 42px;
          img {
            margin-right: 24px;
            width: 87px;
            height: 88px;
          }
          h3 {
            margin: 0;
            font-size: 28px;
            font-weight: normal;
            color: #4F4F6F;
          }
        }
        p {
          margin: 0;
          font-size: 20px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .scenarioDivider {
      margin-bottom: 29px;
      width: 72px;
      height: 5px;
      background: #469DFF;
    }
  }
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 358px;
  padding-top: 50px;
  height: 1336px;
  background: url("img/about_bg.png") no-repeat bottom;
  background-size: contain;
  .aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin: 0;
    h2 {
      margin-bottom: 65px;
      font-size: 36px;
      font-weight: bold;
      color: #4F4F6F;
    }
    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      color: #666666;
      text-indent: 2em;
    }
    .pics {
      display: flex;
      justify-content: space-between;
      margin-top: 105px;
      width: 100%;
      .left {
        position: relative;
        width: 860px;
        height: 768px;
        .pic1 {
          position: absolute;
          top: 0;
          width: 703px;
          height: 406px;
          z-index: 1;
        }
        .pic2 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 693px;
          height: 498px;
        }
      }
      .pic3 {
        width: 507px;
        height: 769px;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 81px;
  background: #393B3D;
  font-size: 14px;
  font-weight: 400;
  color: #9CA9B5;
  .leftCss{
    color: #9CA9B5;
    margin-right: 86px;
    cursor: pointer;
    &:hover {
      color: #469DFF;
    }
  }
  .link {
    color: #9CA9B5;
    margin-left: 86px;
    cursor: pointer;
    &:hover {
      color: #469DFF;
    }
  }
}
